(function($) {

    $('#main-slider').slick({
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow:'<div class="slick-prev col-sm-2">Previous</div>',
      nextArrow: '<div class="slick-next col-sm-2">Next</div>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
    
    $('#client-list').slick({
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      prevArrow:'<div class="slick-prev col-sm-2">Previous</div>',
      nextArrow: '<div class="slick-next col-sm-2">Next</div>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
    
    $('#flexslider-portfolio').flexslider({
        directionNav: true,
        controlNav: false,
        touch: true,
        smoothHeight: true,
        animation: "slide",
        slideshowSpeed: 8000,           
        animationSpeed: 800
    });   
    
})(jQuery); 

